import { ChangeDetectorRef, ChangeDetectionStrategy, Component, OnDestroy, OnInit, HostBinding } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DragulaService } from 'ng2-dragula';
import { ToastrService } from 'ngx-toastr';
import { catchError, distinctUntilChanged, filter, forkJoin, map, mergeMap, Observable, of, Subject, takeUntil } from 'rxjs';
import { AgreementFormBranch, agreementFormCreatedFrom, AgreementFormMode, AgreementFormUser, ControlType, historyAgreementForm, IAgreementForm } from 'src/app/model/agreement.model';
import { User, UserCommunicator } from 'src/app/model/user.interface';
import { ErrorHandlingService } from 'src/app/services/handle-error.service';
import { LoaderService } from 'src/app/services/loader.service';
import { ModalService } from 'src/app/services/modal.service';
import { AgreementService } from 'src/app/services/agreement.service';
import { UserService } from 'src/app/services/user.service';
import { Branch } from 'src/app/model/branch.interface';
import { CompanyService } from 'src/app/services/company.service';
import { AgreementFormClass } from 'src/app/classes/agreement-form.class';
import { HttpErrorResponse } from '@angular/common/http';
import { UniversalOption } from 'src/app/model/universal-option.interface';
import { AgreementClass } from 'src/app/classes/agreement.class';
import { AgreementFormModal, AgreementModal } from 'src/app/classes/modal.class';
import { AbstractModal } from '../../modal/abstract-modal';
import { AgreementFormModalData } from 'src/app/model/modal.interface';
import { CheckDeviceService } from 'src/app/services/check-device.service';
import { ENTERPRISE_ROLES } from 'src/app/resources/roles';
@Component({
  selector: 'app-agreement-form',
  templateUrl: './agreement-form.component.html',
  styleUrls: ['./agreement-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AgreementFormComponent extends AbstractModal implements OnInit, OnDestroy {
  users: User[];
  isEditTitleVisible = false;
  mode: AgreementFormMode;
  AGREEMENT_FORM_MODE = AgreementFormMode;
  controlType = ControlType;
  agreementFormGuid: string;
  maxLength = 255;
  branches: Branch[] = [];
  agreement: AgreementFormClass;
  historyAgreementForm: historyAgreementForm[];
  showEditor = true;
  previewData: AgreementFormModalData;
  scrollable = true;
  isEnterpriseUser: boolean;

  tabs: any = [
    { active: true,
      tabTitle: 'AGREEMENT_FORM.TERMS_OF_AGREEMENT_TITLE'
    },
    { active: false,
      tabTitle: 'AGREEMENT_FORM.CONFIGURE_TITLE'
    }
  ];

  private _ngUnsubscribe$: Subject<void> = new Subject<void>();

  form: FormGroup = this.fb.group({
    title: ['', [Validators.required, Validators.maxLength(this.maxLength)]],
    terms: ['', [Validators.required]],
    branch: ['', [Validators.required]],
    customersAllowed: [[]],
    dinamicForm: this.fb.array([], [Validators.required]),
  });

  constructor(
    modalService: ModalService,
    private fb: FormBuilder,
    private loaderService: LoaderService,
    private userService: UserService,
    private dragulaService: DragulaService,
    private router: Router,
    private route: ActivatedRoute,
    private agreementService: AgreementService,
    private translateService: TranslateService,
    private toastr: ToastrService,
    private errorHandlingService: ErrorHandlingService,
    private companyService: CompanyService,
    private cdr: ChangeDetectorRef,
    private checkDeviceService: CheckDeviceService
  ) {
    super(modalService);
  }

  @HostBinding('class.isModal') isModal = false;

  get title(): FormControl {
    return this.form?.get('title') as FormControl;
  }

  get customersAllowed(): FormControl {
    return this.form?.get('customersAllowed' ) as FormControl;
  }

  get dinamicForm(): FormArray {
    return this.form?.controls['dinamicForm'] as FormArray;
  }

  get branch(): FormControl {
    return this.form?.controls['branch'] as FormControl;
  }

  get status(): FormControl {
    return this.form?.controls['status'] as FormControl;
  }

  get values(): FormArray {
    return this.dinamicForm?.get['values'] as FormArray;
  }

  get terms(): FormControl {
    return this.form?.controls['terms'] as FormControl;
  }

  get currentUserId(): number {
    return this.userService.user.id;
  }

  get isMobile(): boolean {
    return this.checkDeviceService.isMobile();
  }

  ngOnInit(): void {

    this.loaderService.show();

    this.isEnterpriseUser = ENTERPRISE_ROLES.includes(this.userService.role);

    const previewData = this.modal?.data;

    if (previewData) {
      this.isModal = true;
      this.mode = AgreementFormMode.preview;
      this.agreement = previewData;
      this.form.addControl('status', new FormControl(''));
      this.branch.disable();
      this.terms.disable();
      this.status.disable();
      this.getBranches();
      return;
    }

    if (this.router.url.includes('create')) {
      this.mode = AgreementFormMode.create;
      this.title.setValue(this.translateService.instant('AGREEMENT_FORM.PAGE_TITLE_CREATE'));
    } else if (this.router.url.includes('agreement-form-edit')) {
      this.form.addControl('status', new FormControl('', Validators.required));
      this.mode = AgreementFormMode.edit;
    }

    this.dragulaService.createGroup('AGREEMENTFORMCONTROL', {
      moves: (el: Element, container: Element, handle: Element) => {
        return handle.className.includes('handle-drag-and-drop');
      },

    });

    this.dragulaService
      .dropModel('AGREEMENTFORMCONTROL')
      .pipe(takeUntil(this._ngUnsubscribe$))
      .subscribe(({ sourceModel }) => {
        this.showEditor = false;
        this.cdr.detectChanges();
        this.dinamicForm.clear();
        sourceModel.forEach((control: FormControl) => {
          this.dinamicForm.push(control);
        });
        this.showEditor = true;
        this.cdr.detectChanges();
      });

      this.getBranches();
  }

  getBranches(): void {
    this.companyService.getCompanyBranches().pipe(
      mergeMap((branches: Branch[]) => {
        this.branches = branches;
        if (this.mode === AgreementFormMode.edit) {
          this.agreementFormGuid = this.route.snapshot.params.guid;
          return this.agreementService.getAgreementForm(this.agreementFormGuid).pipe(
            mergeMap((data) => {
              this.agreement = data;
              return this.agreementService.getAgreementFormHistory(this.agreementFormGuid);
            })
          );
        } else {
          return of(null);
        }
      }),
      catchError((errorResponse: HttpErrorResponse) =>
        this.errorHandlingService.handleBackendError(errorResponse)
      )).subscribe((data: any | null) => {
        if (this.mode === AgreementFormMode.preview) {
          this.setAgreementFormData(this.modal.data);
        } else if (data) {
          this.setAgreementFormData(this.agreement);
          this.historyAgreementForm = data;
          this.historyAgreementForm.forEach((agreementForm) => {
            if (!agreementForm.creator) {
              agreementForm.creator = {name: this.translateService.instant(agreementFormCreatedFrom[agreementForm.createdFromProcess])};
            }
          });
        }
        this.loaderService.hide();
      });
      if (this.mode !== AgreementFormMode.preview) {
        this.trackFormChanges();
      }
  }

  getUsers(ids: number): Observable<User[]> {
    const companyUsers$ = this.userService.getCompanyUsersByBranchId([ids]);
    const enterpriseUsers$ = this.userService.getEnterpriseUsers();

    return forkJoin([companyUsers$, enterpriseUsers$]).pipe(
      map(([companyUsers, enterpriseUsers]) => {
        const users = [...companyUsers, ...enterpriseUsers];
        return users.filter(user => user.allowedAgreementForms);
      })
    );
  }

  trackFormChanges(): void {
    this.branch.valueChanges
    .pipe(
      distinctUntilChanged(),
      filter(Boolean),
      takeUntil(this._ngUnsubscribe$),
      mergeMap((branchId) => {
        if (branchId &&  !this.branch.disabled) {
          this.loaderService.show();
          return this.getUsers(branchId);
        } else {
          return of(null);
        }
      })
    ).subscribe((users: User[]) => {
      if (users) {
        this.customersAllowed.enable();
        if (this.mode === AgreementFormMode.create) {
          this.users = users.filter(user => user.id !== this.userService.user.id);
        } else {
          this.users = users;
          const currentUser = this.users.find((user) => user.id === this.currentUserId);
          currentUser.disabled = true;
          const creator = this.users.find((user) => user.id === (this.agreement.creator as UserCommunicator).id);
          if (creator) {
            creator.disabled = true;
          }
        }
        this.customersAllowed.setValue(this.users);
        this.loaderService.hide();
      }
    });
  }

  setAgreementFormData(data: AgreementFormClass): void {
    if (data) {
      this.title.patchValue(data.title);
      this.status.patchValue(data.status);
      this.branch.patchValue((data.branch as UniversalOption).id);
      this.terms.patchValue(data.terms);
      this.dinamicForm.patchValue([]);
      data.dinamicForm.forEach((control) => {
        const controlValue = this.fb.group({
          elementType: [control.elementType],
          elementLabel: [control.elementLabel, control.elementType !== this.controlType.info ? [Validators.required, Validators.maxLength(this.maxLength)] : null ],
          textFieldEditorValue: [{ value: '', disabled: true }],
          values: [control.values, control.elementType === this.controlType.dropdown || control.elementType === this.controlType.info ? Validators.required : null],
          selectedValues: [''],
          required: [control.required, [Validators.required]]
        });

        this.dinamicForm.push(controlValue);
      });
    }
    if (this.mode === AgreementFormMode.preview) {
      (<FormArray>this.dinamicForm).controls.forEach(control => {
        control.disable();
      });
    }

    data?.branch ? this.setUsers(data) : this.disableControls();
  };

  setBranch(): void {
    this.customersAllowed.reset(null);
  }

  setUsers(data: AgreementFormClass): void {
    this.loaderService.show();

    this.getUsers((data.branch as AgreementFormBranch).id)
      .subscribe( (users: User[]) => {
        this.users = users;
        if (this.mode === AgreementFormMode.preview) {
          this.agreement.customersAllowed.forEach(customer => {
            if (!this.users.find(user => user.id === customer.id)) {
              this.users.push(customer);
            }
          });
          this.users.forEach((user) => {
            user.disabled = true;
          });
        } else {
          const currentUser = this.users.find((user) => user.id === this.currentUserId);
          currentUser.disabled = true;
          if (this.agreement.creator) {
            const creator = this.users.find((user) => user.id === (this.agreement.creator as UserCommunicator).id);
            if (creator) {
              creator.disabled = true;
            }
          }
        }
        this.customersAllowed.patchValue(data.customersAllowed);
        this.customersAllowed.enable();
        this.loaderService.hide();
      });
  }

  disableControls(): void {
    this.customersAllowed.disable();
  }

  addDropdown(): void {
    const dropdown = this.fb.group({
      elementType: [this.controlType.dropdown],
      elementLabel: ['', [Validators.required, Validators.maxLength(this.maxLength)]],
      textFieldEditorValue: [''],
      selectedValues: [''],
      values: [[], Validators.required],
      required: [false],
    });

    this.dinamicForm.push(dropdown);
  }

  addTextField(): void {
    const textForm = this.fb.group({
      elementType: [this.controlType.text],
      elementLabel: ['', [Validators.required, Validators.maxLength(this.maxLength)]],
      textFieldEditorValue: [{ value: '', disabled: true }],
      selectedValues: [''],
      values: [[]],
      required: [false],
    });

    this.dinamicForm.push(textForm);
  }

  addSignature(): void {
    const signatureForm = this.fb.group({
      elementType: [this.controlType.signature],
      elementLabel: ['', [Validators.required, Validators.maxLength(this.maxLength)]],
      textFieldEditorValue: [{ value: '', disabled: true }],
      selectedValues: [''],
      values: [{value: [], disabled: true}],
      required: [false],
    });

    this.dinamicForm.push(signatureForm);
  }

  addInfoField(): void {
    const infoForm = this.fb.group({
      elementType: [this.controlType.info],
      elementLabel: [''],
      textFieldEditorValue: [{ value: '', disabled: true }],
      selectedValues: [''],
      values: ['', [Validators.required]],
      required: [false],
    });

    this.dinamicForm.push(infoForm);

  }

  addToList(control: FormControl, event?: Event): void {
    if (control.get('selectedValues').invalid) {
      return;
    }
    if (event) {
      event.preventDefault();
    }
    let items = [...control.get('values').value];
    if (control.get('selectedValues').value) {
      if (items.includes(control.get('selectedValues').value)) {
        this.toastr.error(this.translateService.instant('REQUISITION_FORM.ALREADY_ADDED_ITEM'));
        return;
      }
      items = [...items, control.get('selectedValues').value];
      control.get('values').setValue(items);
      control.get('selectedValues').reset();
    }
  }

  removeFromList(control: FormControl, item: string): void {
    const items = control.get('values').value.filter((i) => i !== item);
    control.get('values').setValue(items);
  }

  removeControl(i: number): void {
    this.dinamicForm.removeAt(i);
    this.dinamicForm.updateValueAndValidity();
  }

  submit(): void {
    if (!this.title.value) {
      this.title.setValue(this.translateService.instant('AGREEMENT_FORM.PAGE_TITLE_CREATE'));
    }

    const { valid } = this.form;
    const value = this.form.getRawValue();

    this.form.markAllAsTouched();
    if (!valid) {
      return;
    }

    this.loaderService.show();

    const submit$ = this.mode === AgreementFormMode.create ?
                    this.agreementService.createAgreementForm(value) :
                    this.agreementService.editAgreementForm(value, this.agreementFormGuid);
    submit$
      .pipe(
        catchError((errorResponse: HttpErrorResponse) =>
          this.errorHandlingService.handleBackendError(errorResponse)
        )
      )
      .subscribe(() => {
        const message = this.mode === AgreementFormMode.create ? 'AGREEMENT_FORM.AGREEMENT_FORM_CREATED_SUCCESSFULLY' : 'AGREEMENT_FORM.AGREEMENT_FORM_EDITED_SUCCESSFULLY';
        this.toastr.success(
          this.translateService.instant(message)
        );
        this.router.navigate(['/agreements/agreement-list'], { queryParamsHandling: 'merge' });
        this.loaderService.hide();
      });
  }

  editTitle(): void {
    this.isEditTitleVisible = !this.isEditTitleVisible;
  }

  openPreviewAgreementModal(): void {
    this.form.markAllAsTouched();
    if (!this.form.valid) {
      return;
    }

    const data: AgreementClass = {
      title: this.title.value,
      dinamicForm: this.dinamicForm.value,
      terms: this.terms.value
    };

    this.modalService.addModal(new AgreementModal(data));
  }

  selectTab(tab: any): any {
    this.tabs.forEach((item) => {
      item.active = false;
    });

    tab.active = true;
  }

  openAgreementFormPreview(content: IAgreementForm): void {
    const agreementForm = new AgreementFormClass(content);
    const data: AgreementFormModalData = {
      title: agreementForm.title,
      dinamicForm: agreementForm.dinamicForm,
      terms: agreementForm.terms,
      branch: agreementForm.branch as AgreementFormBranch,
      customersAllowed: agreementForm.customersAllowed as AgreementFormUser[],
      status: agreementForm.status
    };

    this.modalService.addModal(new AgreementFormModal(data));
  }

  moveItem(from: number, to: number):  void {
    const value = this.dinamicForm.getRawValue();
    const element = value[from];
    value[from] = value[to];
    value[to] = element;
    this.dinamicForm.patchValue(value);
    if (value[from].elementType === this.controlType.signature) {
      this.dinamicForm.controls[from].get('values').disable();
    }
    if (value[to].elementType === this.controlType.signature) {
      this.dinamicForm.controls[to].get('values').disable();
    }
    if (value[from].elementType === this.controlType.text) {
      this.dinamicForm.controls[from].get('textFieldEditorValue').disable();
    }
    if (value[to].elementType === this.controlType.text) {
      this.dinamicForm.controls[to].get('textFieldEditorValue').disable();
    }
  }

  ngOnDestroy(): void {
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
    this.dragulaService.destroy('AGREEMENTFORMCONTROL');
  }
}
